<template>
  <div>
    <!-- <div :rail="rail" permanent @click="rail = false" class="accueil-drawer">
      <Accueil></Accueil>
    </div>
    <v-main>-->
    <div
      fluid
      :style="{
        backgroundImage:
          'url(' + require('@/assets/images/' + background_mobile) + ')',
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
        backgroundSize: 'cover',
      }"
      class="background-container"
    >
      <div class="d-flex flex-column">
        <div class="content-container">
          <svg
            class="mt-12 mb-5"
            style="justify-content: center; align-items: center"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="40" height="40" rx="8" fill="#47D881" />
            <path
              d="M26.1466 13.5388C25.8314 13.8824 25.5196 14.2224 25.2044 14.566C24.45 15.3885 23.6956 16.2111 22.9412 17.0336C22.0292 18.0279 21.1205 19.0186 20.2085 20.0129C19.4273 20.8647 18.6427 21.7201 17.8615 22.5719C17.4826 22.9849 17.097 23.3944 16.7248 23.8111C16.708 23.8294 16.698 23.8367 16.6779 23.855C16.7919 23.7599 16.9025 23.6649 17.0165 23.5698C17.0031 23.5772 16.993 23.5808 16.9796 23.5881C17.1137 23.526 17.2479 23.4638 17.3786 23.4053C17.3652 23.409 17.3518 23.409 17.3384 23.4127C17.4859 23.3907 17.6368 23.3688 17.7843 23.3469C17.7709 23.3469 17.7575 23.3469 17.7441 23.3469C17.8916 23.3688 18.0425 23.3907 18.19 23.4127C18.1766 23.409 18.1632 23.4053 18.1498 23.4017C18.2839 23.4638 18.418 23.526 18.5488 23.5845C18.5354 23.5772 18.5253 23.5698 18.5119 23.5662C18.6259 23.6612 18.7366 23.7563 18.8506 23.8513C18.5656 23.6027 18.3208 23.2811 18.0626 22.9996C17.5966 22.4914 17.1305 21.9833 16.6644 21.4752C16.0944 20.8537 15.5278 20.2359 14.9578 19.6144C14.5923 19.216 14.2268 18.8175 13.8647 18.4227C13.5596 18.09 13.1103 17.889 12.6778 17.889C12.2654 17.889 11.7758 18.0864 11.4908 18.4227C11.1991 18.77 10.9812 19.227 11.0013 19.7168C11.0214 20.203 11.1723 20.66 11.4908 21.0109C12.3525 21.9504 13.2176 22.8936 14.0793 23.8331C14.7298 24.5422 15.3803 25.2514 16.0307 25.9606C16.2755 26.2275 16.5169 26.5309 16.8153 26.7247C17.5496 27.1999 18.4818 27.039 19.0819 26.403C19.1088 26.3774 19.1322 26.3481 19.159 26.3189C20.0576 25.3392 20.9562 24.3595 21.8548 23.3798C23.2329 21.8773 24.6076 20.3785 25.9857 18.876C26.8139 17.9731 27.6521 17.0774 28.4736 16.1635C28.487 16.1489 28.4971 16.1379 28.5105 16.1233C28.8156 15.7907 29 15.3008 29 14.8292C29 14.3796 28.8189 13.8459 28.5105 13.5351C28.1919 13.2171 27.7728 12.9795 27.3235 13.0014C26.8876 13.027 26.4652 13.1878 26.1466 13.5388Z"
              fill="white"
            />
          </svg>

          <h2
            class="redo text-center mx-5"
            :style="{
              color: WEBAPPPRO_TEXT_COLOR_1,
              fontSize: WEBAPPPRO_FONT_SIZE_1,
              fontFamily: WEBAPPPRO_FONT_FAMILY_1,
              marginBottom: '10px',
              fontWeight: 'regular',
              'text-transform': 'none',
            }"
          >
            Modification prise en compte
          </h2>
          <div
            class="pl-1 mb-5"
            :style="{
              fontSize: WEBAPPPRO_FONT_SIZE_3,
              fontFamily: WEBAPPPRO_FONT_FAMILY_3,
              color: WEBAPPPRO_TEXT_COLOR_2,
              marginTop: '20px',
              fontWeight: 'bold',
            }"
          >
            Votre photo est en cours de vérification par notre équipe.
          </div>
        </div>
      </div>
    </div>
    <!--</v-main>-->
  </div>
</template>
<script>
import { requestService } from '@/services/request.service'
import { localStorageService } from '@/services/localStorage.service'
//import Accueil from './Accueil.vue'

export default {
  name: 'Final',
  /*components: {
    Accueil,
  },*/
  data() {
    return {
      confEmail: '',
      email: '',
      firstName: '',
      lastName: '',
      address: '',
      errors: [],
      agenceName: '',
      externalId: '',

      styleTitle: localStorageService.getStyle(location.hostname, 'titleStyle'),
      buttonValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonValidColor',
      ),
      WEBAPPPRO_TEXT_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_1',
      ),
      buttonTextValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextValidColor',
      ),
      buttonNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonNeutralColor',
      ),
      buttonTextNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextNeutralColor',
      ),
      icon: localStorageService.getStyle(location.hostname, 'icon'),
      iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),
      color1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_COLOR_1',
      ),
      WEBAPPPRO_FONT_FAMILY_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_1',
      ),
      WEBAPPPRO_FONT_FAMILY_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_2',
      ),
      WEBAPPPRO_TEXT_COLOR_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_2',
      ),
      WEBAPPPRO_FONT_FAMILY_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_3',
      ),
      WEBAPPPRO_FONT_SIZE_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_1',
      ),
      WEBAPPPRO_FONT_SIZE_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_2',
      ),
      WEBAPPPRO_FONT_SIZE_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_3',
      ),
      background_mobile: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BACKGROUND_MOBILE',
      ),
      WEBAPPPRO_CARD_BORDER_ROUND: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_BORDER_ROUND',
      ),
      WEBAPPPRO_ICON_COLOR_INFO: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_ICON_COLOR_INFO',
      ),
      isClicked: false,
      WEBAPPPRO_ICON_COLOR_INFO_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_ICON_COLOR_INFO_2',
      ),
      WEBAPPPRO_ICON_COLOR_WARNING_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_ICON_COLOR_WARNING_2',
      ),
    }
  },
  mounted() {
    if (!localStorageService.getValue('shortliveApiKey')) {
      this.$router.push({ name: 'NotAuthorized' })
      return
    }
    const uuid = localStorageService.getValue('partner_uid')
    if (
      uuid !== undefined &&
      uuid !== null &&
      !localStorageService.getValue('logo')
    ) {
      requestService.get('/partner/uuid/' + uuid).then(response => {
        if (response.data.success) {
          this.agenceName = response.data.name
          if (response.data.logo !== null) this.icon = response.data.logo
          localStorageService.setObject(
            'logo',
            response.data.logo !== null ? response.data.logo : undefined,
          )
          localStorageService.setObject(
            'primaryColor',
            response.data.primaryColor !== null
              ? response.data.primaryColor
              : undefined,
          )
          localStorageService.setObject(
            'secondaryColor',
            response.data.secondaryColor !== null
              ? response.data.secondaryColor
              : undefined,
          )
        }
      })
    }
    localStorageService.clearAll()
  },
}
</script>
<style>
.background-container {
  /*background-size: cover;*/
  height: 100vh !important;
  width: 100vw !important;
  display: flex;
}
.rounded-bottom {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-bottom: 0px;
  background-color: #ffffff;
}
.accueil-drawer {
  width: 300px; /* Ajustez la largeur du drawer selon vos besoins */
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
}
.photo-icon {
  margin: auto;
  width: 17px;
  height: 12px;
}
.text-align-left {
  display: inline-block;
  vertical-align: middle;
}
.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
}
@media only screen and (min-width: 320px) and (max-width: 360px) and (max-height: 639px) {
  .redo {
    font-size: 28px !important;
  }
}
</style>
